<template>
  <v-row>
    <v-col>
      <div v-if="status && statusCustomer" id="data-list">
        <v-snackbars
          :objects.sync="snackbar"
          width="500"
          :timeout="1500"
          bottom
          right
          color="error"
          transition="slide-y-transition"
        >
          <template v-slot:default="{ message }">
            <div v-if="message.status">
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                Ürününden <strong> {{ message.count }} {{ message.birim }}</strong>
                <v-chip :color="message.color" label class="ml-1" small> {{ message.plant }} Fabrikasından </v-chip>
                sepetinize eklendi
              </div>
            </div>
            <div v-else>
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                <strong> Sepetten çıkartıldı</strong>
              </div>
            </div>
          </template>
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <v-snackbar v-model="isColorSnackbarVisible" bottom right color="success">
          <span style="font-size: 16px">
            <strong>Ürün Başarıyla Sepete Eklendi</strong>
          </span>
        </v-snackbar>
        <v-snackbar v-model="isColorSnackbarVisibleError" bottom right color="error">
          <span style="font-size: 16px">
            <strong>Lütfen Ürün Miktarını Giriniz</strong>
          </span>
        </v-snackbar>

        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

        <v-row style="margin: 1px">
          <v-col cols="12" md="10" sm="9">
            <!-- app drawer -->

            <v-card
              :width="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
              tile
              :flat="$vuetify.breakpoint.mdAndDown"
              :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-tl-lg'"
              class="overflow-hidden"
            >
              <v-toolbar
                v-if="!$vuetify.breakpoint.smAndDown"
                :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'"
                :flat="!$vuetify.breakpoint.smAndDown"
                :height="$vuetify.breakpoint.smAndDown ? 57 : 57"
              >
                <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium textColor1 mt-8">
                  <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                    {{ icons.mdiCart }}
                  </v-icon>
                  Sipariş Girişi
                </v-toolbar-title>
              </v-toolbar>

              <v-expansion-panels v-else v-model="panel" flat tile>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-body-2 font-weight-medium" style="display: flex; justify-content: space-between">
                      <v-icon left>
                        {{ icons.mdiCart }}
                      </v-icon>
                      <span class="mr-16"> Sipariş Girişi</span>
                      <span>Filtre</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0">
                    <v-divider></v-divider>
                    <v-card
                      tile
                      touchless
                      floating
                      :right="!$vuetify.rtl"
                      :width="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
                      :height="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
                      app
                      @input="val => $emit('update:is-product-filter-sidebar-active', val)"
                    >
                      <div>
                        <v-text-field
                          v-model="aramaText"
                          filled
                          label="Ad ve Kod ile Ara"
                          :prepend-inner-icon="icons.mdiMagnify"
                        >
                        </v-text-field>
                        <v-select
                          v-model="filter.groupName"
                          label="Araç"
                          :items="groupName"
                          class="custom-select"
                          :menu-props="{ bottom: true, offsetY: true }"
                          filled
                          color="black"
                          clearable
                          hide-details
                        ></v-select>

                        <v-select
                          v-model="filter.marka"
                          label="Marka"
                          :items="marka"
                          class="rounded-0 custom-select"
                          :menu-props="{ bottom: true, offsetY: true }"
                          filled
                          clearable
                          hide-details
                        ></v-select>

                        <v-select
                          v-model="filter.desen"
                          label="Desen"
                          :items="desen"
                          class="rounded-0 custom-select"
                          :menu-props="{ bottom: true, offsetY: true }"
                          filled
                          clearable
                          hide-details
                        ></v-select>

                        <v-select
                          v-model="filter.ebat"
                          label="Ebat"
                          :items="ebat"
                          class="rounded-0 custom-select"
                          :menu-props="{ bottom: true, offsetY: true }"
                          filled
                          clearable
                          hide-details
                        ></v-select>

                        <v-select
                          v-model="filter.mattype"
                          label="Grup"
                          :items="mattype"
                          class="rounded-0 mb-9 custom-select"
                          :menu-props="{ bottom: true, offsetY: true }"
                          filled
                          clearable
                          hide-details
                        ></v-select>

                        <v-checkbox
                          v-model="stoktakiler"
                          color="secondary"
                          style="margin: 10px"
                          hide-details
                          label="Stoktakileri Göster"
                          class="custom-label"
                        ></v-checkbox>
                        <!-- 
                        <v-checkbox
                          v-model="fotograftakiler"
                          color="secondary"
                          style="margin: 10px"
                          hide-details
                          label="Fotoğrafları Göster"
                          class="custom-label"
                        ></v-checkbox>
                        -->
                        <v-btn
                          small
                          style="margin: 5px; max-width: 50px"
                          tile
                          class="text-capitalize ml-3"
                          color="error"
                          @click="filterClear()"
                        >
                          Temizle
                        </v-btn>
                        <v-btn
                          small
                          style="margin: 5px; min-width: 80px"
                          tile
                          class="text-capitalize"
                          color="success"
                          @click="searchData()"
                        >
                          Ara
                        </v-btn>
                      </div>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- table -->

              <v-data-table
                class="mt-10"
                v-model="selectedRows"
                :options.sync="options"
                :loading="loading"
                :headers="tableColumns"
                :items="dataListTable"
                :items-per-page="15"
                fixed-header
                :search="search"
              >
                <!-- 
                   :server-items-length="totalDataListTable"
                :height="dataTableHeight"
                :item-class="itemRowBackground"

                  
              <template v-if="userData.role === 'PLSYR'" v-slot:header.pictures="{ header }">
                <v-btn color="secondary" fab small elevation="0" @click="refreshCustomer">
                  <v-icon>
                    {{ icons.mdiArrowLeft }}
                  </v-icon>
                </v-btn>
              </template>
             -->
                <template #[`item.product_title`]="{ item }">
                  <v-tooltip right color="white">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex flex-column responsive-container">
                        <div
                          v-bind="attrs"
                          :class="
                            $vuetify.breakpoint.smAndDown
                              ? 'font-weight-bold '
                              : 'd-block font-weight-bold text-truncate mb-1'
                          "
                          v-on="on"
                        >
                          {{ item.product_title }}
                        </div>
                        <div>
                          <v-row>
                            <v-col class="small-font">{{ item.product_code }}</v-col>
                            <v-col class="small-font">{{ item.aalgrup }}</v-col>
                            <v-col class="small-font">{{ item.aalgroupName1 }}</v-col>
                            <v-col class="small-font">{{ item.aalkonum }}</v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                    <v-img
                      v-if="item.pictures && !$vuetify.breakpoint.xsOnly"
                      width="400"
                      height="500"
                      :src="'https://onlineapi.anlas.com/' + item.pictures"
                    ></v-img>
                  </v-tooltip>
                </template>

                <template v-slot:header.tekirdag_fabrikasi="{ header }">
                  <div class="my-1">
                    <div class="d-flex align-left justify-space-between">
                      <div style="width: 180px" class="text-left">
                        <span class="ml-8"> Net Fiyat </span>
                        <br />
                        (KDV ve GKKP hariç)
                      </div>
                      <div style="width: 150px" class="text-left">
                        Stok <br />
                        Miktarı
                      </div>
                      <div style="width: 80px" class="text-left">Sipariş<br />Miktarı</div>
                    </div>
                  </div>
                </template>

                <template #[`item.pictures`]="{ item }">
                  <v-card
                    v-if="item.pictures"
                    tile
                    class="rounded d-flex align-center justify-center"
                    :width="!$vuetify.breakpoint.xsOnly ? 30 : 60"
                    :height="!$vuetify.breakpoint.xsOnly ? 50 : 90"
                    :color="item.pictures ? 'white' : 'primary'"
                  >
                    <v-img
                      v-if="item.pictures"
                      :width="!$vuetify.breakpoint.xsOnly ? 100 : 120"
                      :height="!$vuetify.breakpoint.xsOnly ? 50 : 90"
                      :src="'https://onlineapi.anlas.com/' + item.pictures"
                    ></v-img>

                    <v-icon v-else>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-card>

                  <v-card
                    v-else
                    tile
                    class="rounded d-flex align-center justify-center"
                    :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                    :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                    :color="item.pictures ? 'white' : 'primary'"
                  >
                    <v-img
                      v-if="item.pictures"
                      :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                      :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                      :src="'https://onlineapi.anlas.com/' + item.pictures"
                    ></v-img>
                    <v-icon v-else>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-card>

                  <!-- <v-card
                  tile
                  class="rounded d-flex align-center justify-center"
                  :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :color="item.pictures ? 'white' : 'primary'"
                >
                  <v-img
                    v-if="item.pictures"
                    :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :src="'https://onlineapi.anlas.com/' + item.pictures"
                  ></v-img>
                  <v-icon v-else>
                    {{ icons.mdiCameraOff }}
                  </v-icon>
                </v-card> -->
                </template>

                <template #[`item.tekirdag_fabrikasi`]="{ item }">
                  <div
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-4 ml-16' : 'd-flex justify-space-between align-center'"
                  >
                    <div class="text-caption font-weight-bold text-right mb-6">
                      <div :class="item.product_price_01 > 0 ? 'success--text' : 'error--text'" style="line-height: 1">
                        {{ item.product_price_01 | currency }}
                      </div>
                    </div>
                    <div v-if="item.stok > 10" class="mb-6 ml-16 text-right">10+</div>
                    <div v-else class="mb-6 ml-16 text-right">{{ item.stok }}</div>
                    <div style="width: 172px">
                      <!--   v-if="item.product_price_01" -->
                      <Counter
                        :uid="item.id"
                        :birim="item.birim"
                        :birimRitim="item.birim_ritim"
                        :miktar="item.product_pic_01"
                        :paym-cond="item.paymCond"
                        plant-id="01"
                        bg-color="transparent elevation-0"
                        button-color="white"
                        :dark="true"
                        :light="false"
                        :status="false"
                        :filled="false"
                        :solo="true"
                        @selected="selected"
                        @bildiri="bildiri"
                      ></Counter>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="2" sm="3">
            <v-card
              tile
              touchless
              floating
              :right="!$vuetify.rtl"
              :width="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
              :height="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
              app
              @input="val => $emit('update:is-product-filter-sidebar-active', val)"
            >
              <div>
                <v-text-field
                  v-model="aramaText"
                  filled
                  label="Ad ve Kod ile Ara"
                  :prepend-inner-icon="icons.mdiMagnify"
                  @keyup.enter="searchData()"
                >
                </v-text-field>
                <v-select
                  v-model="filter.groupName"
                  label="Araç"
                  :items="groupName"
                  class="custom-select"
                  :menu-props="{ bottom: true, offsetY: true }"
                  filled
                  color="black"
                  clearable
                  hide-details
                ></v-select>

                <v-select
                  v-model="filter.marka"
                  label="Marka"
                  :items="marka"
                  class="rounded-0 custom-select"
                  :menu-props="{ bottom: true, offsetY: true }"
                  filled
                  clearable
                  hide-details
                ></v-select>

                <v-select
                  v-model="filter.desen"
                  label="Desen"
                  :items="desen"
                  class="rounded-0 custom-select"
                  :menu-props="{ bottom: true, offsetY: true }"
                  filled
                  clearable
                  hide-details
                ></v-select>

                <v-select
                  v-model="filter.ebat"
                  label="Ebat"
                  :items="ebat"
                  class="rounded-0 custom-select"
                  :menu-props="{ bottom: true, offsetY: true }"
                  filled
                  clearable
                  hide-details
                ></v-select>

                <v-select
                  v-model="filter.mattype"
                  label="Grup"
                  :items="mattype"
                  class="rounded-0 mb-9 custom-select"
                  :menu-props="{ bottom: true, offsetY: true }"
                  filled
                  clearable
                  hide-details
                ></v-select>
                <v-checkbox
                  v-model="stoktakiler"
                  color="secondary"
                  style="margin: 10px"
                  hide-details
                  label="Stoktakileri Göster"
                  class="custom-label"
                ></v-checkbox>
                <!-- 
                <v-checkbox
                  v-model="fotograftakiler"
                  color="secondary"
                  style="margin: 10px"
                  hide-details
                  label="Fotoğrafları Göster"
                  class="custom-label"
                ></v-checkbox>
              -->
                <v-btn
                  small
                  style="margin: 5px; max-width: 50px"
                  tile
                  class="text-capitalize ml-3"
                  color="error"
                  @click="filterClear()"
                >
                  Temizle
                </v-btn>
                <v-btn
                  small
                  style="margin: 5px; min-width: 80px"
                  tile
                  class="text-capitalize"
                  color="success"
                  @click="searchData()"
                >
                  Ara
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

        <v-alert v-if="!$store.state.plasiyerCustomer" tile type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>

        <div v-else>
          <v-row>
            <v-col>
              <v-card
                :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
                rounded="xl"
                class="mx-auto mt-md-9"
                :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
                :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
              >
                <v-img
                  class="white--text align-center"
                  :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                  src="@/assets/images/pages/header-01.jpg"
                  gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
                >
                  <div class="px-6">
                    <div class="py-5">
                      <div class="text-h6">İlave Bilgiler</div>
                      <div class="text-caption">Lütfen Aşağıdan Seçiniz</div>
                    </div>
                    <v-select v-model="kotaDeger" label="Kota Seçiniz" :items="kotaItems" @change="kotaChange">
                      <template v-slot:item="data">
                        <div class="my-3">
                          <div>
                            {{ data.item.text }}
                          </div>
                          <div v-if="data.item.limit > 0" class="text-caption">
                            <div class="secondary--text mr-3">
                              Limit : {{ data.item.limit | currency({ symbol: 'KG', fractionCount: '0' }) }}
                            </div>
                            <div class="warning--text mr-3">
                              Kullanılan : {{ data.item.sKullanilan | currency({ symbol: 'KG', fractionCount: '0' }) }}
                            </div>
                            <div class="error--text">
                              Kalan :
                              {{
                                (data.item.limit - data.item.sKullanilan)
                                  | currency({ symbol: 'KG', fractionCount: '0' })
                              }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-slot:selection="data">
                        <div>
                          {{ data.item.text }}
                        </div>
                      </template>
                    </v-select>

                    <v-select v-model="tipDeger" label="Sipariş Tipi Seçiniz" :items="tipItems" @change="tipChange">
                    </v-select>

                    <v-select
                      v-model="kondisyonDeger"
                      :disabled="!tipDeger"
                      label="Ödeme Tipi Seçiniz"
                      :items="kondisyonItems"
                      @change="kondisyonChange"
                    />
                    <v-btn block depressed rounded color="tertiary" x-large class="my-6" @click="ilaveDevam">
                      Devam
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Counter from '@/components/Counter.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import TrackingFilter from '@/views/orderTracking/tracking-list/TrackingFilter.vue'
import {
  mdiArrowLeft,
  mdiCamera,
  mdiCameraOff,
  mdiCart,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiFlash,
  mdiMagnify,
  mdiPlusThick,
  mdiSearchWeb,
  mdiSquareEditOutline,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'

import orderStoreModule from '../orderStoreModule'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    Counter,
    VSnackbars,
    CustomerList,
    TrackingFilter,
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'
    const sendData = ref({})

    const isColorSnackbarVisible = ref(false)
    const isColorSnackbarVisibleError = ref(false)
    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    const filterData = item => {
      idFilter.value = item.id || null
      siparisDurumFilter.value = item.siparisDurum || null
      odemeDurumFilter.value = item.odemeDurum || null
      startDateFilter.value = item.startDate || null
      endDateFilter.value = item.endDate || null
      minPriceFilter.value = item.minPrice || null
      maxPriceFilter.value = item.maxPrice || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i

      sendData.value = { ...item }
    }
    const filter = ref({
      urunAdi: '',
    })
    const filterClear = () => {
      isFilterUserSidebarActive.value = false
      fotograftakiler.value = false
      stoktakiler.value = false
      aramaText.value = ''
      filter.value = {
        urunAdi: '',
      }
    }

    const hideOutStock1 = ref(0)
    const isFilterUserSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const dataListTable = ref([])
    const dataItem = ref({})
    const snackbar = ref([])
    const search = ref('')
    const product_title = ref('')
    const product_code = ref('')
    const status = ref(false)
    const isFilterTrackingSidebarActive = ref(false)
    const stoktakiler = ref(false)
    const fotograftakiler = ref(false)
    const aramaText = ref('')
    const tableColumns = [
      {
        text: 'Foto',
        value: 'pictures',
        width: '80px',
        height: '60px',
        sortable: false,
      },
      /*
      {
        text: 'Kod',
        value: 'product_code',
        width: '100px',
        sortable: false,
        filter: value => {
          if (!product_code.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_code.value.toLocaleUpperCase('TR')) !== -1
        },
      },
      */
      {
        text: 'Ürün Bilgisi',
        value: 'product_title',
        sortable: false,
        class: 'max-width-250',
        filter: value => {
          if (!product_title.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_title.value.toLocaleUpperCase('TR')) !== -1
        },
      },

      {
        text: '',
        value: 'tekirdag_fabrikasi',
        align: 'right',

        sortable: false,
      },
    ]
    if (window.screen.availWidth <= 600) {
      tableColumns.forEach(item => {
        delete item.filter
      })
    }
    const statusCustomer = ref(false)
    const kotaItems = ref([])
    const kotaDeger = ref()
    const kondisyonItems = ref([])
    const kondisyonDeger = ref()
    const grup = ref([])
    const groupName = ref([])
    const marka = ref([])
    const desen = ref([])
    const ebat = ref([])
    const mattype = ref([])

    const tipItems = ref([
      { text: 'Ön Ödemelli', value: 'O1P' },
      { text: 'Normal', value: 'O1' },
      { text: 'Bağlantılı (Yapım Aşamasında)', value: 'O9' },
    ])
    const tipDeger = ref()
    const delivery_date = store.state['app-order'].deliveryDate

    onMounted(() => {
      fetchDatas()
      if (userData.role !== 'CUST') {
        ilaveDevam()
      }

      fetchCategories()

      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else if (userData.role === 'CUST') {
        status.value = true
        loading.value = true
        statusCustomer.value = true
        fetchBasket()
      }
    })

    const condChange = val => {
      const paymcond = val.paymCond == '100' ? val.paymCond : `0${val.paymCond}`
      dataListTable.value.find(item => {
        if (item.id === val.id) {
          item.priceAll.forEach(el => {
            if (el.paymcond == paymcond) {
              if (el.plant == '01') {
                item.product_price_01 = el.price1
              } else if (el.plant == '02') {
                item.product_price_02 = el.price1
              }
            }
          })
        }
      })
    }

    watch(
      () => stoktakiler.value,
      () => {
        if (stoktakiler.value == true) {
          hideOutStock1.value = 1
        } else {
          hideOutStock1.value = 0
        }

        console.log('değer= ' + hideOutStock1.value)
        // fetchBasket()
        // refreshBasket()
      },
    )

    const kotaChange = () => {
      postData({
        method: 'setMemberKota',
        limitnum: kotaDeger.value,
        delivery_date,
      }).then(response => {
        const validFromRaw = response.detail.validFrom.slice(0, 10)
        const validFromSplit = validFromRaw.split('.')
        const validFrom = `${validFromSplit[2]}-${validFromSplit[1]}-${validFromSplit[0]}`

        const validUntilRaw = response.detail.validUntil.slice(0, 10)
        const validUntilSplit = validUntilRaw.split('.')
        const validUntil = `${validUntilSplit[2]}-${validUntilSplit[1]}-${validUntilSplit[0]}`

        store.commit('app-order/DELIVERY_DATE_MAX', validUntil)
        store.commit('app-order/DELIVERY_DATE_MIN', validFrom)
      })
    }

    const kondisyonChange = () => {
      postData({
        method: 'setMemberCondition',
        condition: kondisyonDeger.value,
      }).then(response => {})
    }

    const tipChange = () => {
      postData({
        method: 'changeOrderType',
        orderType: tipDeger.value,
      }).then(response => {
        // kondisyonItems
        store.commit('PAYMENT_TYPE', tipDeger.value)
        kondisyonItems.value = []
        kondisyonDeger.value = null
        for (const [key, value] of Object.entries(response.detail)) {
          kondisyonItems.value.push({
            text: value,
            value: key,
          })
        }
      })
    }
    const searchData = () => {
      //fetchBasket()
      loading.value = true
      fetchDatas()
    }

    const refreshCustomer = () => {
      loading.value = true
      kondisyonItems.value = []
      kotaItems.value = []
      kondisyonDeger.value = ''
      kotaDeger.value = ''
      tipDeger.value = ''
      statusCustomer.value = false
      //plasyirFn()
      fetchBasket()
    }

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        loading.value = true
        kondisyonItems.value = []
        kotaItems.value = []
        kondisyonDeger.value = ''
        kotaDeger.value = ''
        tipDeger.value = ''
        statusCustomer.value = false
        ilaveDevam()

        // fetchBasket()
      },
    )

    const ilaveDevam = () => {
      status.value = true
      statusCustomer.value = true
      fetchBasket()
      fetchDatas()
      // değişen yer  if (kotaDeger.value && kondisyonDeger.value && tipDeger.value) {
      if (kondisyonDeger.value && tipDeger.value) {
      }
    }
    const totalDataListTable = ref(0)
    const loading = ref(true)
    const options = ref({
      page: 1,
      itemsPerPage: 15,
      sortBy: ['baslik'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])
    const count = ref(1)
    const selected = item => {
      // fetchBasket()
      // const snackbarData = dataListTable.value.find(i => i.id === item.uid)
      // if (item.number != 0) {
      //   // count.value = restaurant.value
      //   snackbar.value.push({
      //     color: null,
      //     message: {
      //       status: true,
      //       plant: item.plantId === '01' ? 'Tekirdağ' : 'Konya',
      //       color: item.plantId === '01' ? 'secondary' : 'warning',
      //       product: snackbarData.product_title,
      //       count: item.number,
      //       birim: 'Ad',
      //     },
      //   })
      // } else {
      //   snackbar.value.push({
      //     color: 'error',
      //     message: {
      //       status: false,
      //       plant: item.plantId === '01' ? 'Tekirdağ' : 'Konya',
      //       color: item.plantId === '01' ? 'secondary' : 'warning',
      //       product: snackbarData.product_title,
      //       count: item.number,
      //       birim: 'Ad',
      //     },
      //   })
      // }
    }
    const itemRowBackground = item => (item.selected ? 'style-1' : 'style-2')

    /*
    watch(
      () => store.getters['app-order/doneBasket'],
      () => {
        fetchDatas(store.getters['app-order/doneBasket'])
        console.log('x' + store.getters.value)
        // fetchBasket()
        // refreshBasket()
      },
    )
    */

    const fetchBasket = async () => {
      await store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
        } else {
          // console.table('hatalı =>', response)
        }
      })
    }

    const fetchCategories = val => {
      //productCategories.value = []
      grup.value = []
      groupName.value = []
      marka.value = []
      desen.value = []
      ebat.value = []
      mattype.value = []

      const method = 'aalFilterGeneral'

      store
        .dispatch('postMethod', {
          method,
        })
        .then(response => {
          if (response.error === true) {
            return console.log('error')
          }

          /*GroupName - Araç
          groupName.value.push({
            text: 'Hepsi',
          })

          */
          for (const [key, value] of Object.entries(response.aalFilterGroupNameList)) {
            groupName.value.push({
              text: value.filter,
            })
          }

          groupName.value.sort((a, b) => a.text.localeCompare(b.text))

          //Marka

          for (const [key, value] of Object.entries(response.aalFilterBrandList)) {
            marka.value.push({
              text: value.filter,
            })
          }
          marka.value.sort((a, b) => a.text.localeCompare(b.text))

          //Desen

          for (const [key, value] of Object.entries(response.aalFilterDesenList)) {
            desen.value.push({
              text: value.filter,
            })
          }

          desen.value.sort((a, b) => a.text.localeCompare(b.text))

          //Ebat

          for (const [key, value] of Object.entries(response.aalFilterEbatList)) {
            ebat.value.push({
              text: value.filter,
            })
          }

          ebat.value.sort((a, b) => a.text.localeCompare(b.text))

          // MatType
          for (const [key, value] of Object.entries(response.aalFilterMattypeList)) {
            mattype.value.push({
              text: value.filter,
            })
          }

          mattype.value.sort((a, b) => a.text.localeCompare(b.text))
        })
    }

    const fetchDatas = async basketList => {
      isFilterUserSidebarActive.value = false

      const page = 1
      const count = 6
      const plant = '01'
      const method = 'getProductsByCtgId'

      const prdName = aramaText.value
      const aalGroup = filter.value.grup
      const aalgroupname = filter.value.groupName
      const aalbrand = filter.value.marka
      const aaldesen = filter.value.desen
      const aalebat = filter.value.ebat
      const hideoutstock = hideOutStock1.value
      const aalmattype = filter.value.mattype

      await store
        .dispatch('postMethod', {
          method,

          page,
          count,
          plant,
          prdName,
          //aalGroup,
          aalgroupname,

          aalbrand,
          aaldesen,
          aalebat,
          aalmattype,
          hideoutstock,
        })
        .then(response => {
          if (response.error == 1) {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          } else {
            const paymcond = kondisyonDeger.value == '100' ? kondisyonDeger.value : `0${kondisyonDeger.value}`
            const productList = response.detail.products.map(item => {
              const items = {}
              items.id = item.id
              items.product_code = item.urun_kodu
              items.aalgrup = item.aalgrup
              items.aalkonum = item.aalkonum
              items.aalgroupName1 = item.aalgroupname
              items.product_title = item.baslik
              items.stok = item.stok
              items.paymCond = kondisyonDeger.value
              items.pictures = item.resim
              items.birim_ritim = item.birim_ritmi
              items.product_01 = true
              items.product_02 = true
              items.grup = item.aalFilterGroupList
              items.priceAll = item.priceAll
              if (item.priceAll.length > 0) {
                const price_01 = item.priceAll.find(i => i.plant === '01')
                const price_02 = item.priceAll.find(i => i.plant === '02')
                if (price_01) {
                  items.product_price_01 = price_01?.price1
                }
                if (price_02) {
                  items.product_price_02 = price_02?.price1
                }
                /*
              if (userData.role === 'PLSYR' && kondisyonDeger.value != '003') {
                item.priceAll.forEach(el => {
                  if (el.paymcond == paymcond) {
                    if (el.plant == '01') {
                      items.product_price_01 = el.price1
                    } else if (el.plant == '02') {
                      items.product_price_02 = el.price1
                    }
                  }

                })
              } else {

              }
              */
              }
              if (typeof items.product_price_01 === 'undefined') {
                items.product_01 = false
              }
              if (typeof items.product_price_02 === 'undefined') {
                items.product_02 = false
              }

              // if (item.priceAll.length > 0) {
              //   const price_01 = item.priceAll.find(i => i.paymcond === '01')
              //   const price_02 = item.priceAll.find(i => i.paymcond === '02')
              //   if (price_01) {
              //     items.product_price_01 = price_01.price1
              //   } else {
              //     items.product_01 = false
              //   }
              //   if (price_02) {
              //     items.product_price_02 = price_02.price1
              //   } else {
              //     items.product_02 = false
              //   }
              // } else {
              //   items.product_01 = false
              //   items.product_02 = false
              // }

              return items
            })

            if (basketList) {
              basketList.forEach(i => {
                productList.find(findItem => {
                  if (findItem.id == i.uid) {
                    findItem.selected = true
                    findItem.paymCond = i.paymCond
                    if (i.selected_plant === '01') {
                      findItem.product_pic_01 = Number(i.qty)
                    } else if (i.selected_plant === '02') {
                      findItem.product_pic_02 = Number(i.qty)
                    }
                  }
                })
              })
            }
            dataListTable.value = productList
            totalDataListTable.value = Number(response.detail.toplam)
            loading.value = false
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    return {
      statusFind,
      userData,
      isColorSnackbarVisible,
      isColorSnackbarVisibleError,
      ebat,
      dataItem,
      dataListTable,
      tableColumns,
      totalDataListTable,
      loading,
      itemRowBackground,
      selected,
      sendData,
      grup,
      groupName,
      marka,
      options,
      dataTotalLocal,
      selectedRows,
      fetchDatas,
      count,
      snackbar,
      search,
      product_title,
      product_code,
      searchData,
      status,
      statusCustomer,
      kotaDeger,
      kotaChange,
      kotaItems,
      kondisyonItems,
      kondisyonDeger,
      isFilterUserSidebarActive,
      kondisyonChange,
      tipItems,
      filterData,
      desen,
      tipDeger,
      tipChange,
      ilaveDevam,
      filterClear,
      stoktakiler,
      fotograftakiler,
      aramaText,
      refreshCustomer,
      filter,
      hideOutStock1,
      condChange,
      isFilterTrackingSidebarActive,
      isFilterNumber,
      mattype,
      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiFlash,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiMagnify,
        mdiArrowLeft,
        mdiSearchWeb,
        mdiCart,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    bildiri(bildiri) {
      if (bildiri == 1) {
        this.isColorSnackbarVisibleError = false
        this.isColorSnackbarVisible = true
      } else if (bildiri == 0) {
        this.isColorSnackbarVisible = false
        this.isColorSnackbarVisibleError = true
      }
    },

    addBasket(plant, product, id, count, birim) {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: id,
          qty: count,
          plant,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              html: response.detail.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',

              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.snackbar.push({ product, count, birim })
          }
        })
    },
    async addToBasket(plants, product, id, count, birim) {
      //değişen kısım   if (plants.length === 1)
      if (plants.length !== 1) {
        this.addBasket(plants[0].plant, product, id, count, birim)

        return
      }

      const plantsMap = new Map(plants.map(object => [object.plant, object.stext]))

      const { value: plant } = await Vue.swal({
        icon: 'info',
        title: 'Fabrika Seçimi',
        text: 'Lütfen ürünü eklemek istediğiniz fabrikayı seçiniz',
        input: 'select',
        inputOptions: plantsMap,
      })

      if (plant) {
        this.addBasket(plant, product, id, count, birim)
      }
    },
    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style scoped lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.search-mobil {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 70px;
}
.textColor {
  color: white;
}

.v-select__selection {
  color: grey !important ;
}

.theme--light.v-select .v-select__selection--comma {
  color: rgba(255, 255, 255, 0.87);
}

.search-mobil-plasiyer {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 135px !important;
}
.text-plain {
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
}
.po-0 {
  .v-toolbar__content {
    padding: 0px 18px 0px 15px !important;
  }
}
// .v-data-table__wrapper::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #3b355a;
// }
// .v-data-table__wrapper::-webkit-scrollbar-thumb {
//   background-color: #0083e8;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_konya {
//   border-bottom: 1px solid #ffb400 !important ;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_tekirdag {
//   border-bottom: 1px solid #0083e8 !important ;
// }

.min-width-400 {
  min-width: 360px;
}
.style-1 {
  background-color: rgba(255, 255, 255, 0.05);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: auto !important;
  }
  .theme--light tr {
    background-color: #fff !important;
  }
  .theme--dark tr {
    background-color: #3b355a !important;
  }
  .v-data-table__empty-wrapper {
    td {
      position: relative !important;
    }
  }
  tr {
    position: relative;
    padding: 15px 0;

    border-bottom: thin solid rgba(231, 227, 252, 0.14);

    td:nth-child(1) {
      position: absolute;

      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(2),
    td:nth-child(3) {
      padding-top: 5px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
      .v-data-table__mobile-row__cell {
        padding-left: 70px;
        text-align: left !important;
      }
    }
    td:nth-child(4) {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(5) {
      display: flex;
      justify-content: flex-end;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
  }
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border: none !important;
  }
}
.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 30%) !important;
}
.paymCond-hidden {
  display: none !important;
}
.rounded-kor {
  .v-input__slot {
    padding: 0 !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}
.custom-table tbody tr {
  height: 450px !important;
}

.v-select-height .v-select__slot {
  height: 40px !important; /* Yükseklik değeri */
}
.custom-select .v-select__selection {
  color: black !important;
}

.custom-select .v-select__selection--comma {
  color: black !important;
}

.custom-select .v-select__selection--disabled {
  color: black !important;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 0.03);
}
.textColor1 {
  color: black !important;
}
.small-font {
  font-size: 11px; /* veya istediğiniz boyutu belirleyebilirsiniz */
}
.arama {
  font-size: 15px;
  color: #1d1717;
}
.custom-label .v-label {
  font-size: 12px; /* İstediğiniz küçük font boyutunu burada belirtebilirsiniz */
}
.theme--light.v-sheet .v-card__title {
  color: #ffffff !important;
}
/* Küçük ekranlar için stil kuralları */
@media (max-width: 576px) {
  .responsive-container {
    width: 100%;
    /* Diğer stil ayarları */
  }
}

/* Orta boy ekranlar için stil kuralları */
@media (min-width: 577px) and (max-width: 992px) {
  .responsive-container {
    width: 80%; /* Örnek bir genişlik değeri */
    /* Diğer stil ayarları */
  }
}

/* Büyük ekranlar için stil kuralları */
@media (min-width: 993px) {
  .responsive-container {
    width: 420px; /* Örnek bir genişlik değeri */
    /* Diğer stil ayarları */
  }
}
</style>
